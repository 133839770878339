<template>
    <div class="container1150">
        <header><strong>虹吸式排水系统</strong></header>
        <main>
            <p>
                虹吸式排水系统可实现零坡度有组织排水，代替传统排水过滤层。满足绿色节能建筑标准，实现海绵城市建设要求的渗、滞、蓄、净、用、排的标准
            </p>
            <div class="img1"></div>
            <h2>产品特性</h2>
            <p>
                ● ·
                取消找坡层、保护层、隔离层，代替传统排水过滤层，解决大面积顶板找坡困难问题，实现零坡度有组织排水
            </p>
            <div class="img2"></div>
            <p>● · 材料强度高，可根据承重等级适用于不同场所，应用范围广泛</p>
            <div class="img3"></div>
            <p>
                ● ·
                每10000平方米的虹吸式排水系统可以积蓄120立方米的雨水量，经过净化设施后，可以达到回用水水质，有助于实现海绵城市削减面源污染和控制径流总量的目标。
            </p>
            <div class="img4"></div>
            <p>● · 材料性能优异，绿色环保，复合海绵城市绿色节能建筑规范</p>
            <div class="img5"></div>
            <p>● · 施工方便，维护简单，节省3/4的施工周期，造价节省1/4-1/3</p>
            <div class="img6"></div>
        </main>
    </div>
</template>
<script>
export default {
    data() {
        return {};
    },
};
</script>
<style lang="scss" scoped>
.container1150 {
    width: 1150px;
    header {
        height: 70px;
        line-height: 70px;
        text-align: center;
        font-size: 26px;
    }
    main {
        p {
            // height: 20px;
            font-size: 16px;
            line-height: 30px;
        }
        h2 {
            height: 60px;
            line-height: 60px;
        }
        // .text-img {
        //     display: flex;
        //     justify-content: space-around;
        //     p {
        //         width: 40%;
        //         padding: 50px 0 0 20px;
        //         box-sizing: border-box;
        //     }
        //     .img1 {
        //         // width: 100%;
        //         width: 500px;
        //         height: 300px;
        //         margin: -60px auto 0;
        //         background-image: url("/img/product/6-3.jpg");
        //         background-repeat: no-repeat;
        //         background-size: 100% 100%;
        //     }
        // }

        // .img-list {
        //     display: flex;
        //     width: 100%;
        //     .img1 {
        //         // width: 100%;
        //         width: 550px;
        //         height: 450px;
        //         margin: 10px auto;
        //         background-image: url("/img/product/8-1.jpg");
        //         background-repeat: no-repeat;
        //         background-size: 100% 100%;
        //     }
        //     .img2 {
        //         // width: 100%;
        //         width: 550px;
        //         height: 450px;
        //         margin: 10px auto;
        //         background-image: url("/img/product/7-2.jpg");
        //         background-repeat: no-repeat;
        //         background-size: 100% 100%;
        //     }
        // }
        .img1 {
            // width: 100%;
            width: 1100px;
            height: 600px;
            margin: 10px auto;
            background-image: url("/img/product/8-1.jpg");
            background-repeat: no-repeat;
            background-size: 100% 100%;
        }
        .img2 {
            width: 1094px;
            height: 320px;
            margin: 10px auto;
            background-image: url("/img/product/8-2.jpg");
            background-repeat: no-repeat;
            background-size: 100% 100%;
        }
        .img3 {
            width: 1094px;
            height: 400px;
            margin: 10px auto;
            background-image: url("/img/product/8-3.jpg");
            background-repeat: no-repeat;
            background-size: 100% 100%;
        }
        .img4 {
            width: 900px;
            height: 600px;
            margin: 10px auto;
            background-image: url("/img/product/8-4.jpg");
            background-repeat: no-repeat;
            background-size: 100% 100%;
        }
        .img5 {
            width: 900px;
            height: 600px;
            margin: 10px auto;
            background-image: url("/img/product/8-5.jpg");
            background-repeat: no-repeat;
            background-size: 100% 100%;
        }
        .img6 {
            width: 900px;
            height: 600px;
            margin: 10px auto;
            background-image: url("/img/product/8-6.jpg");
            background-repeat: no-repeat;
            background-size: 100% 100%;
        }
    }
}
</style>